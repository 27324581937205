import React, {Fragment, useEffect, useState} from "react";
import PageTitle from "../../layouts/PageTitle";
import {Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../../../services/AxiosInstance";
import { mapStateToProps, withRouter } from "../../../utils";
import { connect } from "react-redux";


const UjianDetail = (props) => {
    console.log('props', props);
    const [data, setData] = useState([]);

    const [token, setToken] = useState(null);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    const navigate = useNavigate();
    const [ujian, setUjian] = useState([]);
    useEffect(() => {
        console.log('id', id);
        if(id == null){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'ID Ujian Tidak Ditemukan. Anda akan di arahkan ke halaman daftar ujian',
                willClose: () => {
                    navigate('/ujian/list');
                }
            })
        }else{
            loadUjianDetail();
        }
    }, [])

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('id-ID', {
            year: 'numeric',
            month: 'long', // Nama bulan
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Format 24-jam
        }).format(date);
    };
    const loadUjianDetail = async () => {
        try {
            const res = await axiosInstance.get(`test-online/siswa/ujian/${id}`);
            console.log(res);
            var dataUjian = res.data.data;
            setUjian(dataUjian);
            const data = [
                { key: 'Nama Siswa', value: props.user.name },
                // { key: 'NISN', value: '0000000' },
                // { key: 'Kelas', value: 'XII' },
                // { key: 'Rombel', value: 'RPL-0' },
                { key: 'Nama Sekolah', value: props.user.detail.profile.institusi.nama_institusi },
                { key: 'Kelas', value: dataUjian?.detail.kelas },
                { key: 'Rombel', value: dataUjian?.detail.rombel },
                { key: 'Nama Ujian', value: dataUjian?.detail.nama },
                { key: 'Mata Pelajaran', value: dataUjian?.paket_soal.detail.mata_pelajaran },
                { key: 'Tanggal dan Waktu Ujian', value: formatDateTime(dataUjian?.ujian.started_at.replace(" ", "T")) + ' ( ' + (dataUjian.durasi / 60) + ' Menit )' },
                // { key: 'Jenis Soal', value: 'P, BS' },
                // { key: 'Jumlah Soal', value: '60' },
                { key: 'Petunjuk', value: 'Bacalah semua soal dengan hati-hati sebelum menjawab' },
                // { key: 'Status', value: 'Sudah Terkirim' }
            ];

            setData(data);

            if(dataUjian.status == "started"){
                navigate('/ujian/process?id='+id);
            }
        } catch (error) {
            console.log(error);
            if (error.code == "ERR_BAD_REQUEST") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${error.response.data.message}`,
                    willClose: () => {
                        navigate('/ujian/list');
                    }
                })
            }
        }
    }

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const handleClick = async () => {
        setIsButtonLoading(true);
        try {
            if(!token){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Token Belum Diisi'
                })
                return;
            }
            // var res = await axiosInstance.get(`test-online/siswa/ujian/content/${ujian.id}`);
            // console.log(res)
            var params = {
                "token": token
            }
            var res = await axiosInstance.put(`test-online/siswa/ujian/start/${ujian.id}`, params);
            console.log('ujian : ', res)
            setIsButtonLoading(false);
            navigate('/ujian/process?id='+id);
        } catch (error) {
            console.log(error);
            setIsButtonLoading(false);
            if (error.code == "ERR_BAD_REQUEST") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${error.response.data.message}`,
                    willClose: () => {
                        navigate('/ujian/list');
                    }
                })
            }
        }
    };
    return (
        <Fragment>
            <PageTitle activeMenu="Ujian" motherMenu="Ujian"/>
            <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
                <Row>
                    <Col xl={12}>
                        <Card name="default-tab" className="dz-card">
                            <Card.Header className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <Card.Title>Detail Ujian</Card.Title>
                                </div>
                                {/*<div>*/}
                                {/*    <Button className="me-2" variant="primary light btn-sm">*/}
                                {/*        Preview*/}
                                {/*    </Button>*/}
                                {/*    <Button className="me-2" variant="primary btn-sm">*/}
                                {/*        Mulai*/}
                                {/*        &nbsp;<i className="bi bi-skip-end-fill"></i>*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                            </Card.Header>
                            <Card.Body>
                                <ListGroup as="ul" variant="flush">
                                    {data.map((list, i) => (
                                        <Fragment key={i}>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col sm={3}>{list.key}</Col>
                                                    {/*<Col sm={1}>:</Col>*/}
                                                    <Col sm={8}>: {list.value}</Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </Fragment>
                                    ))}
                                    {ujian.status == "finished" ? (
                                        <>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col sm={3}>Status</Col>
                                                    {/*<Col sm={1}>:</Col>*/}
                                                    <Col sm={8} className=" d-sm-flex justify-content-between align-items-center">:&nbsp; {ujian.status}</Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </>
                                    ) : (
                                        <>
                                        <ListGroup.Item as="li">
                                            <Row>
                                                <Col sm={3}>Token</Col>
                                                {/*<Col sm={1}>:</Col>*/}
                                                <Col sm={8} className=" d-sm-flex justify-content-between align-items-center">:&nbsp;<input
                                                    type="text"
                                                    className="form-control-sm form-control"
                                                    placeholder="Masukkan Token Ujian"
                                                    onChange={(e) => setToken(e.target.value)}
                                                /></Col>
                                            </Row>
                                        </ListGroup.Item>
                                        </>
                                    )}
                                    
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer className=" d-sm-flex justify-content-between align-items-center">
                                <Card.Text className=" text-dark d-inline">
                                    {/*Last updated 3 mins ago*/}
                                </Card.Text>

                                { ujian.status == "finished" ? (
                                    <></>
                                ) : (
                                    <Button disabled={isButtonLoading} onClick={handleClick} className="btn btn-primary text-white">
                                    { isButtonLoading ? "Loading..." : (
                                        <>
                                        Mulai&nbsp;<i className="bi bi-skip-end-fill"></i>
                                        </>
                                    ) }
                                </Button>
                                ) }
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Fragment>
);
}

export default withRouter(connect(mapStateToProps)(UjianDetail));